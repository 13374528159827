import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
// import ProductView from '../views/ProductView.vue'
const routes = [
  { path: '/', redirect: '/Avalúo' },
  {
    path: '/:product_type',
    name: 'home',
    component: HomeView
  }

  // {
  //   path: '/products',
  //   name: 'products',
  //   component: ProductView
  // }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
