import { createApp } from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'
import router from './router'
import 'flag-icons/css/flag-icons.min.css';
import { createVPhoneInput } from 'v-phone-input';
import { VAutocomplete } from 'vuetify/components';
import './assets/phone.css';



loadFonts()
let app = createApp(App)
app.config.globalProperties.API_URL = process.env.VUE_APP_API_URL

const vPhoneInput = createVPhoneInput({
  countryIconMode: 'svg',
  preferCountries: ['MX','US'],

});
app.component('VAutocomplete', VAutocomplete);
app.use(router)
  .use(vPhoneInput)
  .use(vuetify)
  .mount('#app')

