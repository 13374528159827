<template>
  <div class="d-flex align-center flex-column mt-10">
    <v-col xs="10" sm="10" md="8">
      <v-card>
      <v-form v-model="isFormValid" @submit.prevent="submit">
        <v-card-title>
          <v-row align="center" justify="center">
            <v-col md="2" cols="2" sm="2">
              <v-img width="10vw" :src="require('@/assets/logo.png')"></v-img>
              Pagos de kredi

            </v-col>
          </v-row>
        </v-card-title>
      
        <v-card-text>
          <v-container>

            <v-row align="center" justify="center">
              <v-col md="6" cols="12" sm="12">
                <v-text-field variant="outlined" color="primary" v-model="name" :rules="rules" label="Nombre completo">
                </v-text-field>
              </v-col>
              <v-col md="6" cols="12" sm="12">
                <v-text-field variant="outlined" color="primary" v-model="email" :rules="emailRules"
                  label="Correo"></v-text-field>
              </v-col>
              <v-col md="6" cols="12" sm="12">

                <v-phone-input :rules="phoneRules" variant="outlined" color="primary" v-model="phone" label="Teléfono" country-label="Lada"
                  country-aria-label="" invalid-message="debes de ingresar un teléfono valido" />

              </v-col>
              <v-col md="6" cols="12" sm="12">
                <v-select variant="outlined" color="primary" return-object v-model="product" :rules="productRules"
                  label="Selecciona producto" :items="products" item-title="description" item-value="id"></v-select>
              </v-col>
              <v-col cols="12">
                <a href="https://kredi.mx/terminos-y-condiciones" target="_blank">Términos y condiciones</a>

              </v-col>
            </v-row>

          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-container>
            <v-row align="center" justify="center">
              <v-col cols="6">
                <v-btn size="large" type="submit" block :disabled="isLoading" color="primary" variant="outlined">
                  Continuar
                </v-btn>
              </v-col>
            </v-row>
          </v-container>

        </v-card-actions>
      </v-form>
    </v-card>
  </v-col>
</div></template>

<script setup>

</script>
<script>
import axios from 'axios'
import { vPhoneInput } from 'v-phone-input';
// @ is an alias to /src
export default {
  name: 'HomeView',
  components: { vPhoneInput },
  mounted() {
    axios
      .get(this.API_URL + 'products/' + this.$route.params.product_type)
      .then((response) => {
        const formatter = new Intl.NumberFormat('es-MX', {
          style: 'currency',
          currency: 'MXN',
        });
        this.products = response.data.map(x => {
          return {
            description: `${x['fields']['Descripción']} (${formatter.format(x['fields']['Precio'])}) `,
            price: x['fields']['Precio'],
            airtable_id: x['fields']['ID Airtable Producto'],
            product_key: x['fields']['Código del producto']
          }
        })
      })
  },
  data: () => ({
    isFormValid: false,
    isLoading: false,
    products: [],
    name: '',
    email: '',
    phone: '',
    product: null,
    rules: [
      value => !!value || 'El nombre es requerido',
    ],
    phoneRules: [
      value => !!value || 'El teléfono es requerido',
    ],
    productRules: [
      value => !!value || 'El producto es requerido',
    ],
    emailRules: [
      v => !!v || 'el correo electrónico requerido',
      v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'Debes ingresar un correo valido'
    ],
  }),
  methods: {
    async submit() {

      if (this.isFormValid) {
        this.isLoading = true;
        const response = await axios.post(this.API_URL + 'customer',
          {
            name: this.name,
            email: this.email,
            product: this.product,
            phone: this.phone
          }
        )
        // console.log(response);
        window.location.href = response.data.url
      }

    }
  }
}
</script>
